/******************************************************************
 * 
 * login.js
 * login popup functionality
 * on load authenticaion
 * uses a context to store authenticaion information
 * 
 * ***************************************************************/
 
/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React, { useState, useEffect } from 'react';
import { Link,navigate } from "gatsby";
import { Auth } from "aws-amplify";
import ReactGA from 'react-ga';
import { useAppContext } from "../libs/contextLib";
import {checkCreateCompany} from "../libs/loginLib";

export default function Login(props) {
  const [key, setKey] = useState("");
  const [isAuthenticated, userHasAuthenticated] = useAppContext();
  let loginRef = React.createRef();
  let location = props.location;
  let noBlur=0;
  useEffect(() => {
    if(typeof(location)==='undefined') {
      location=props;
    }
    onLoad();
    
  },[]);
  
  async function login(res) {
    //if this is the signup page, we should redirect to the homepage.
    if(location.pathname=='/signup' || location.pathname=='/signup/') {
      navigate("/");
    }
    console.log("login res");
    console.log(res);
    ReactGA.set({ userId: res.username });
    
    var k=res.signInUserSession.idToken.jwtToken;
    setKey(k);
    props.setKey(k);
    checkCreateCompany(k,function() {loadco(k);});  //callback is loadco
  }

  function loadco(ikey) {
      console.log("location:");
      console.log(location);
      console.log("key");
      console.log(ikey);
      if(location.pathname==='/') {
        fetch("https://api.valtrace.com/returnCompanies", {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain',
            'Authorization': ikey //was idToken for REST API
          }
        }).then(res => res.text()).then(res => {
          //look to see if there is a company
          console.log("return companies:");
          let list=JSON.parse(res);
          console.log(list);
          props.setCompanies(list.companies);
        });
      }
  }

  async function onLoad() {
    console.log("login props");
    console.log(props);
    try {
      let cS=await Auth.currentSession();
      console.log("currentSession");
      console.log(cS);
      setKey(cS.idToken.jwtToken);
      props.setKey(cS.idToken.jwtToken);
      userHasAuthenticated(true);
      loadco(cS.idToken.jwtToken);
    }
    catch(e) {
      if (e !== 'No current user') {
        console.log(e);
      }
      userHasAuthenticated(false);
      props.setCompanies([]);
    }
  }
  
  function showLogin() {
    //console.log("login");
    console.log("display login dialog");
    loginRef.current.style.display="block";
    let inp=loginRef.current.getElementsByTagName('input');
    inp[0].focus();
    document.addEventListener('mouseup', mUp, false);
    document.addEventListener('mousedown', mDown, false);
    document.addEventListener('keydown',handleKeyDown,false);
    props.setZup();
  }
  
  function handleKeyDown(e) {
    console.log("keydown handled");
    if (e.key === "Tab") {
      console.log("keydown handled");
      noBlur=1;
//      const form = e.target.form;
//      const index = Array.prototype.indexOf.call(form, e.target);
//      form.elements[index + 1].focus();
//      e.preventDefault();
//      noBlur=0;
      setTimeout( () => {noBlur=0;},0);
    }
  }
  
  async function logOut() {
    await Auth.signOut();
    userHasAuthenticated(false);
    window.localStorage.removeItem("email");
    //we need to route to signup
    navigate("/signin");
  }
  function mDown(e) {
    if(loginRef.current && loginRef.current.contains(e.target)) {
      console.log("mDown in");
      noBlur=1;
    } else {
      killPop();
    }
  }
  function mUp() {
    console.log("mUp");
    noBlur=0;
  }
  function killPop(){
    if(loginRef.current) {
      console.log('killpop');
      loginRef.current.style.display="none";
      document.removeEventListener('mouseup', mUp, false);
      document.removeEventListener('mousedown', mDown, false);
      document.removeEventListener('keydown', handleKeyDown,false);
    }
    props.setZdown();
  }
  function endPop(x) {
    console.log("ENDPOPx");
    //console.log(x);
    if(noBlur==0) {
      console.log("noBlur!!!");
      setTimeout(() => {
        console.log(document.activeElement);
        if(document.activeElement.className!=="form-control") {
          killPop();
        }
      });
    }
  }  

  return (
    isAuthenticated ? 
    <div>
      <span className='menuHover' onClick={logOut} style={{cursor:'default'}}>Sign out</span>
    </div>
    :
    <div>
    { (location.pathname!='/signin' && location.pathname!='/signin/') &&
      <span>
        <span className='menuHover' onClick={showLogin} style={{cursor:'default'}}>Login</span>
        <div onBlur={endPop} onMouseDown={mDown} style={{display:'none',position:'absolute'}} ref={loginRef}>
          <LoginForm login={login} setText={props.setText} />
        </div>
      </span>
    }
    { (location.pathname!='/signin' &&  location.pathname!='/signin/') && (location.pathname!='/signup' && location.pathname!='/signup/') &&
      <span style={{marginLeft:'min(25px,4.5vw)'}}></span>
      
    }
    { (location.pathname!='/signup' && location.pathname!='/signup/') &&
      <span className='menuHover' style={{}}><Link className='menuHover' to="/signup/">Sign up</Link></span>
    }
    </div>
  );
}

Login.defaultProps = {
    setCompany: () => {},
    setKey: () => {}
};

export function LoginForm(props) {
  const [isAuthenticated, userHasAuthenticated] = useAppContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(event) {
    console.log("handleSubmit");
    event.preventDefault();
    console.log(email);
    try {
      var res=await Auth.signIn(email, password);
      console.log("Logged in");
      console.log(res);
      userHasAuthenticated(true);
      props.login(res);
    } catch (e) {
      console.log(e); //e.message
      console.log(props);
      props.setText("Failure: "+e.message);
    }
  }
  let topstyle={position: 'absolute',
    minWidth: '20em',
    minHeight: '12em',
    backgroundColor: 'white',
    zIndex: '10',
    right: '-3.5em',
    top: '10px',
    borderRadius: '2px',
    border: '1px solid rgba(0,0,0,.25)'
  };
  let innerStyle={
     transform:'translateY(calc(10%))',
  };
  return (
    <div className="LoginForm" style={topstyle} >
      <style>{`
        .LoginForm input {
          border-radius: 2px;
          border: 1px solid #aaa;
          padding: 2px;
        }
        .arrow-up {
          width: 0; 
          height: 0; 
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid white;
        }
      `}
      </style>
      <div className='arrow-up' style={{position:'absolute',right:'10px',top:'-10px'}}></div>
      <div style={innerStyle} >
          <form onSubmit={handleSubmit}>
            <div style={{padding:'5px 0px', textAlign:'center'}} className="form-group">
                <input placeholder="Email" style={{width:'16em',lineHeight: '32px'}} type="email" className="form-control Hip" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div style={{padding:'0px 0px', textAlign:'center'}} className="form-group">
                <input type="password" placeholder="Password" style={{width:'16em',lineHeight: '32px'}} className="form-control Hip" value={password} onChange={e => setPassword(e.target.value)} />
            </div>
            <div style={{padding:'5px 0px', textAlign:'center'}}><Link to="/forgotPassword/"><span style={{color:'blue'}}>Forgot Password</span></Link></div>
            <div style={{clear:'both',padding:'5px 0px',width:'100%', textAlign:'center'}}>
              <button type="submit" className='btn' disabled={!validateForm()} >Log in</button>
            </div>
          </form>
        </div>
    </div>
  );
}
