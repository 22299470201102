/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React from 'react';
import './feedback.css';
import Dots from './dots.js';
import { renderToStaticMarkup } from 'react-dom/server';

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.popupRef=React.createRef();
    this.inputRef=React.createRef();
    this.feedbackRef=React.createRef();
    this.setText=props.setText;
    this.popOpen=0;
  	this.state = {
    	name: '',
    	email: '',
    	note: ''
  	};
  }
  clearForm() {
	  this.setState({name: '',email:'',note:''});
  }
  onNameChange(event) {
	  this.setState({name: event.target.value});
  }

  onEmailChange(event) {
	  this.setState({email: event.target.value});
  }

  onNoteChange(event) {
	  this.setState({note: event.target.value});
  }
  
  handleSubmit(e) {
    e.preventDefault();
    let message={};
    Object.assign(message, this.state);
    let d=new Date();
    message.date=d.toJSON();
    console.log(message);
    fetch('https://api.valtrace.com/feedback',{
        method: "POST",
        body: JSON.stringify(message),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Origin': 'null'
        },
      }).then(
    	(response) => (response.json())
       ).then((response)=> {
      if (response.status === 'success'){
        this.setText("Thank you for your feedback."); 
        this.clearForm();
        const wrapper = this.popupRef.current;
        wrapper.classList.toggle('Appear');
        wrapper.classList.toggle('Disappear');

      }else if(response.status === 'fail'){
        this.setText("Message failed to send.");
      }
    });
  }
  
  startPop = (x) => {
    if(this.popOpen==0) {
      console.log("startPop");
      const wrapper = this.popupRef.current;
      var dims=this.feedbackRef.current.getBoundingClientRect();
      let offset=0;
      if((window.innerWidth-dims.right-80)<0) {
        offset=(window.innerWidth-dims.right-80);
      }
      wrapper.firstChild.style.right=-offset+"px";
      console.log(dims);
      console.log("offset");
      console.log(offset);
      console.log("window width:");
      console.log(window.innerWidth);
      wrapper.classList.toggle('Appear');
      wrapper.classList.toggle('Disappear');
      console.log("wrapper");
      console.log(wrapper);
      const inp = this.inputRef.current;
      inp.focus();
      document.addEventListener('mouseup', this.mUp, false);
      document.addEventListener('mousedown', this.mDown, false);
      document.addEventListener('keydown',this.handleKeyDown,false);
      this.noBlur=0;
      this.popOpen=1;
    }
  }

  handleKeyDown = (e) => {
    console.log("keydown handled");
    if (e.key === "Tab") {
      console.log("keydown handled");
      this.noBlur=1;
      setTimeout( () => {this.noBlur=0;},0);
    }
  }
  
  mDown=(e) => {
    if(this.popupRef.current.contains(e.target)) {
      console.log("mDown in");
      this.noBlur=1;
    } else {
      this.killPop();
    }
  }
  mUp=(e) => {
    console.log("mUp");
    this.noBlur=0;
  }

  endPop=(x)=> {
    if(this.noBlur==0) {
      console.log("noBlur==0");
      setTimeout(() => {
        console.log(document.activeElement);
        if(document.activeElement.className!=="form-control") {
          this.killPop();
        }
      });
    }
  }  

  killPop = (x) => {
    if(this.popOpen==1) {
      console.log('killpop');
      const wrapper = this.popupRef.current;
      wrapper.classList.toggle('Appear');
      wrapper.classList.toggle('Disappear');
      document.removeEventListener('mouseup', this.mUp, false);
      document.removeEventListener('mousedown', this.mDown, false);
      document.removeEventListener('keydown', this.handleKeyDown,false);
      this.popOpen=0;
    }
  }  
  
  render() {
    let fstyle_con={
       transform:'translateY(calc(-25px - 100%))',
       width:'17.25em',
       position:'absolute',
       left:'-5.9em'
    };
    const svgString = encodeURIComponent(renderToStaticMarkup(<Dots bsize=".25" />));
    const dataUri = `url("data:image/svg+xml,${svgString}")`;
    let fstyle={
      backgroundImage: dataUri,
       backgroundRepeat: 'repeat, repeat',
       backgroundSize: '4px 4px',
       backgroundColor:'fff',
       color:'white',
       padding:'7px',
       borderRadius: '3px',
       border:'1px solid #888'
    };
    return (
      <span style={{position:'relative',marginLeft:'min(25px,4.5vw)'}}>
        <span ref={this.feedbackRef} onClick={this.startPop} className='menuHover' style={{cursor:'default'}}>Feedback</span>
          <div ref={this.popupRef} onBlur={this.endPop} className='Disappear' style={fstyle_con}>
            <div style={fstyle}>
              <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST" style={{margin:'7px'}}>
                <div style={{padding:'5px 0px'}} className="form-group">
                    <input ref={this.inputRef} placeholder="Name" style={{float:'right',width:'15em',lineHeight:'32px'}} type="text" className="form-control Hip" value={this.state.name} onChange={this.onNameChange.bind(this)} />
                </div>
                <div style={{padding:'5px 0px',clear:'both'}} className="form-group">
                    <input placeholder="Email" style={{float:'right',width:'15em',lineHeight:'32px'}} type="email" className="form-control Hip" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                </div>
                <div style={{padding:'5px 0px',clear:'both'}} className="form-group">
                    <textarea placeholder="Note" style={{float:'right',width:'calc(15em - 0px)',resize:'none'}} className="form-control Hip" rows="5" value={this.state.note} onChange={this.onNoteChange.bind(this)} />
                </div>
                <div style={{clear:'both',padding:'5px 0px',width:'100%', textAlign:'center'}}>
                  <button type="submit" className='btn'>Submit</button>
                </div>
              </form>
            </div>
          <div style={{margin:'0 auto'}} className='arrow-down' />
        </div>
      </span>
    );
  }
}
export default Feedback;

