/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React from 'react';
import './alert.css';
import Dots from './dots.js';
import close from './closed.png';
import { renderToStaticMarkup } from 'react-dom/server';

class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.state={text:props.text};
  }

  endPop = (x) => {
      this.setState({text:''});
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.props.text !== prevProps.text) {
      this.setState({text:this.props.text});
    }
  }

  render() {
    let top_style={
        width:'100%',
        height:'100%',
        backgroundColor: 'rgb(0,0,0,.4)',
        position: 'fixed',
        top:'0',
        left:'0',
        right:'0',
        bottom:'0',
        zIndex:40
    };
    let fstyle_con={
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    };
    const svgString = encodeURIComponent(renderToStaticMarkup(<Dots bsize=".15" />));
    const dataUri = `url("data:image/svg+xml,${svgString}")`;

    let fstyle={
      backgroundImage: dataUri,
       backgroundRepeat: 'repeat, repeat',
       backgroundSize: '2px 2px',
       backgroundColor:'fff',
       color:'black',
       fontSize: '20px',
       padding:'20px',
       borderRadius: '4px',
       border:'1px solid #777'
    };
    if(this.state.text==='') {
        return null;
    }
    return (
        <div style={top_style}>
            <div  style={fstyle_con}>
              <img alt='' onClick={(e) => this.endPop()}
                style={{position:'absolute',right:'-12px',top:'-12px', width:'25px'}} 
                src={close} />
              <div style={fstyle}>
                {this.state.text}
              </div>
            </div>
        </div>
    );
  }
}
export default Alert;

