import React from 'react';
//import {Link} from "react-router-dom";
import { Link } from "gatsby";
import Feedback from './feedback.js';
import Guilloche from "./guilloche.js";
import { renderToStaticMarkup } from 'react-dom/server';
import loader from '../images/Infinity-2.7s-200px.svg';
import dollar_background from '../images/100paper_bg2sl.jpg';

export default function Footer(props) {
    const svgString = encodeURIComponent(renderToStaticMarkup(<Guilloche />));
    const dataUri = `url("data:image/svg+xml,${svgString}")`;
    
    let botStyle= {
      padding: props.pHeight+' 0',
      backgroundColor:'#fff',
      borderRadius:'0px 4px 0px 0px',
      backgroundImage: dataUri,
      position:'relative',
      zIndex:'10'
    };

  return (
    <div style={{width:'100%',display: 'flex',position:'absolute',bottom:'0',zIndex:'12'}}>
        <style>{`
          .mleft {
            margin-left: min(25px,4.5vw);
          }
        `}
        </style>
        <div className="bottomMenu" style={botStyle}>
          <div style={{marginRight:'min(25px,4.5vw)',textAlign:'left',marginLeft:'0',fontSize:'13px',position:'relative',bottom: '1px',whiteSpace: 'nowrap'}}>
            <img alt='...' ref={props.loadRef} src={loader} style={{
              height: '13px',
              position: 'absolute',
              left: '-2px',
              bottom: '4px',
              visibility: 'hidden'
            }}/>            
            <span className='mleft'><Link className='menuHover' to="/faq/">FAQ</Link></span>
            <span className='mleft'><Link className='menuHover' to="/privacy/">Privacy</Link></span>
            <span className='mleft'><Link className='menuHover' to="/terms/">Terms</Link></span>
            <span className='mleft'><Link className='menuHover' to="/about/">About</Link></span>
            <span className='mleft'><Link className='menuHover' to="/blog/">Blog</Link></span>
            <Feedback setText={props.setText.bind(this)}/>
          </div>
          {props.children}
          <div style={{zIndex:'-1',position:'absolute', height: '60%',borderTop: '1px solid rgba(0,0,0,.96)', borderRight: '1px solid rgba(0,0,0,.96)', borderRadius: '0 5px 0 0',right: '-2px',top: '-2px',left: '0'}}></div>
        </div>
      <div style={{borderBottom:'1px solid rgba(0,0,0,.96)',flexGrow:'1',position:'relative'}}>
        <div style={{boxShadow: '0px 5px 2px #000',position:'absolute',right:0,height: '50%',bottom: '0', left: '-1px', borderLeft: '3px double rgba(0,0,0,.96)', borderBottom: '3px double rgba(0,0,0,.96)',borderRadius: '0 0 0 5px'}}></div>
        &nbsp;
      </div>
    </div>
  );
}