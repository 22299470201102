import { useContext, createContext } from "react";
import Amplify, { Auth } from "aws-amplify";
import ReactGA from 'react-ga';

export const checkCreateCompany = async function(key,callback) {
    var currentUserInfo = await Auth.currentUserInfo();
    console.log("currentUserInfo");
    console.log(currentUserInfo);
    const companyName = currentUserInfo.attributes['custom:companyName'];
    if(companyName.length>3) {
      const endDate = currentUserInfo.attributes['custom:endMonth'];
      const industrySel = currentUserInfo.attributes['custom:industry'];
      const reasonsSel = currentUserInfo.attributes['custom:reason'];
      let message={name:companyName,endMonth: endDate,industry:industrySel,reason:reasonsSel};
      await fetch("https://api.valtrace.com/createCompany", {
          method: "POST",
          body: JSON.stringify(message),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': key
          }
        }).then(res => res.text()).then(async res => {
          // load company here
          if(window.location.hostname=="www.valtrace.com") {
            ReactGA.event({
              category: 'Value Company',
              action: 'Create Company',
              value: 5
            });
          }
          const user = await Auth.currentAuthenticatedUser();
          await Auth.updateUserAttributes(user, { 'custom:companyName': '' });
          if(callback)
            callback();
        });
    } else {
      if(callback)
        callback();
    }
};