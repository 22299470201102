import React from 'react';

export default function dots(props) {
  //dollar color #f1f1e5
  return (
        <svg
        	xmlns="http://www.w3.org/2000/svg"
        	version="1.1"
        	width="60"
        	height="60">
        	<g id="background">
        		<rect width="60" height="60" fill="white" />
        	</g>
        	<g id="dots" transform="scale(30,30)">
        		<circle cx="0" cy="0" r={props.bsize} fill="black" />
        		<circle cx="2" cy="0" r={props.bsize} fill="black" />
        		<circle cx="0" cy="2" r={props.bsize} fill="black" />
        		<circle cx="2" cy="2" r={props.bsize} fill="black" />
        		<circle cx="1" cy="1" r={props.bsize*1} fill="black" />
        	</g>
        </svg>
  );
}
